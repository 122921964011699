<template>
  <div id="list-header-wrapper">
    <div class="active-filters-count">
      <span class="filter-count">{{ selectedFilterOptionsCount }}</span>
      Active filters
    </div>
    <font-awesome-icon class="separator-icon" :icon="['fas', 'circle']"/>
    <b-button
      :disabled="!selectedFilterOptionsCount"
      variant="link"
      @click="resetFiltersAndGardens()">Reset all</b-button>
    <div class="results-count-wrapper">
      <div class="results-count">
        <span class="filter-count">{{ gardens.length }}</span>
        results
      </div>
    </div>
    <SortButton class="sort-button-component"/>
    <div
      v-if="dateRangeFilterUsed"
      class="date-range-notice">
      These gardens open during your specified date range, please select each garden to see all available dates.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SortButton from '@/components/gardenList/SortButton'

export default {
  name: 'ListHeader',

  components: {
    SortButton,
  },

  computed: {
    ...mapGetters('garden', [
      'gardens',
    ]),

    ...mapGetters('filter', [
      'selectedFilterOptionsCount',
      'sectionFilterSelected',
    ]),

    dateRangeFilterUsed() {
      return this.sectionFilterSelected('date_open_from')?.length && this.sectionFilterSelected('date_open_to')?.length ? true : false;
    },
  },

  methods: {
    ...mapActions('garden', [
      'searchGardens',
    ]),
    ...mapActions('filter', [
      'resetFiltersAndGardens',
    ]),
  },
}
</script>

<style scoped lang="scss">
#list-header-wrapper {
  border-bottom: 1px solid $color-grey-light;
  color: $color-grey-main;
  display: flex;
  align-items: center;
  margin: 0.75rem 1rem 0 1rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0;
  }

  .active-filters-count {
    .filter-count {
      margin-right: 0.5rem;
    }
  }
  .separator-icon {
    font-size: 0.2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .btn {
    font-size: 0.75rem;

    &.btn-link {
      text-decoration: none;
      &:focus{
        box-shadow: none;
      }
      &:active{
        box-shadow: 0 0 0 0.2rem rgba($color-yellow-main, 0.25);
      }
    }
  }
  .results-count-wrapper {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
