<template>
<div class="info-window-wrapper ngs-font">
  <div class="info-header">
    <div class="image">
      <!-- -lazy -->
      <b-img
        fluid
        v-if="garden.featured_image"
        :src="garden.featured_image"
        :alt="`${garden.name} - image 1`"/>
      <img-placeholder v-else/>
    </div>
    <div
        class='info-window-group-type-icon-elipse'>
        <div>
          <ngs-icon
            :tag="treeIcon"
            alt="group type icon"
            :showTooltip="false"/>
        </div>
      </div>
  </div>
  <div class="info-details">
    <div class="garden-name">{{ garden.name }}</div>
    <div class="garden-address">
      <div class="garden-address-top">
        {{ garden.address_2 ? `${garden.address_2}, ` : null }}
        {{ garden.address_3 ? `${garden.address_3}, ` : null }}
      </div>
      <div class="garden-address-bottom">
        {{ garden.town ? `${garden.town}, ` : null }}
        {{ garden.county ? `${garden.county}, ` : null }}
        {{ garden.postcode ? garden.postcode : null }}
      </div>
    </div>
  </div>
  <div class="info-footer">
    <div class="garden-tags">
      <ngs-icon-group :tags="garden.tags" :loadedTags="loadedTags"/>
    </div>
    <div class="details-button-wrapper">
      <b-button
        class="details-button"
          variant="primary"
        @click="goToGarden(garden)">
        Details
      </b-button>
    </div>
  </div>
</div>
</template>

<script>
import { snakeCase } from 'lodash'
import NgsIconGroup from '@/components/ngsIcons/NgsIconGroup';
import router from '@/router/index'
import { otherIcons } from '@/components/map/constants/mapSettings';
import NgsIcon from '@/components/ngsIcons/NgsIcon';
import ImgPlaceholder from '@/components/images/imgPlaceholder';

export default {
  name: 'MarkerInfoWindow',

  components: {
    ImgPlaceholder,
    NgsIconGroup,
    NgsIcon,
  },

  props: {
    garden: {
      type: Object,
      required: true,
    },
    treeIcon: {
      type: Object,
    },
    loadedTags: {
      type: Array,
      default() {
        return []
      },
    },
  },

  computed: {
    isParentGarden() {
      return this.garden.garden_type_id === 2;
    },
    groupTypeIcon() {
      return this.garden.garden_type_id === 2 ? otherIcons.treeGroupBlack : otherIcons.treeBlack
    },
  },

  methods: {
    goToGarden(garden) {
      router.push({ name: 'Garden', params: { gardenId: garden.id, gardenName: snakeCase(garden.name) } })
    },
  },
}
</script>

<style lang='scss'>
.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d,
.info-window-wrapper {
  min-height: 277px;
  max-height: unset !important;
}
</style>

<style scoped lang='scss'>
.info-window-wrapper {
  max-width: 16.191rem;

  .info-header {
    position: relative;
    max-width: 16.191rem;

    .image {
      max-height: 9.25rem;
      overflow: hidden;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .info-window-group-type-icon-elipse{
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 1.75rem;
      height: 1.75rem;
      background: $color-white-main;
      border-radius: 50%;
      text-align: center;

      div {
        width: 50%;
      }
    }
  }

  .info-details {
    margin: 1rem 1rem 0rem 1rem;

    .garden-name {
      font-size: 24px;
      line-height: 24px;
      color: #000000;
    }

    .garden-address {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: $color-grey-main;
    }
  }

  .info-footer {
    margin: 0rem 1rem 1rem 1rem;
    font-size: 13px;
    font-weight: bold;
    line-height: 150.02%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: flex-end;

    .garden-tags {
      font-size: 15px;
      padding-bottom: 2px;
    }

    .details-button-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;

      .btn {
        font-size: 20px;
        line-height: 20px;
        border-radius: 0px;
      }
    }
  }
}
</style>
