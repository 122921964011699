<template>
  <div
    class="map-legend-wrapper row"
    :class="{'is-in-map-search-page' : isInMapSearchPage, 'is-out-map' : isOutMap}"
  >
      <div class="col-6 col-md-12">
        <div class="map-legend-item">
          <div class="marker-wrapper">
            <b-img :src="icons['markerOpening']" alt="Openings marker"></b-img>
          </div>
          <div class="marker-label">Openings</div>
        </div>
        <div class="map-legend-item">
          <div class="marker-wrapper">
            <b-img :src="icons['markerArrangement']" fluid alt="By arrangement marker"></b-img>
          </div>
          <div class="marker-label">By arrangement</div>
        </div>
        <div class="map-legend-item">
          <div class="marker-wrapper">
            <b-img :src="icons['markerOpeningAndArrangement']" fluid alt="Openings & By arrangement marker"></b-img>
          </div>
          <div class="marker-label">Openings & By arrangement</div>
        </div>
      </div>
      <div class="col-6 col-md-12">
        <div class="map-legend-item">
          <div class="marker-wrapper">
            <b-img :src="icons['markerCluster']" fluid alt="Cluster marker"></b-img>
          </div>
          <div class="marker-label">Cluster</div>
        </div>
        <div class="map-legend-item">
          <div class="marker-wrapper">
            <b-img :src="icons['markerClosed']" fluid alt="Closed marker"></b-img>
          </div>
          <div class="marker-label">No upcoming openings</div>
        </div>
      </div>
  </div>
</template>

<script>
import { markerIcons } from '@/components/map/constants/mapSettings'

export default {
  name: 'MapLegend',

  props: {
    isInMapSearchPage: {
      type: Boolean,
      default: false,
    },
    isOutMap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icons() {
      return markerIcons
    },
  },
}
</script>

<style scoped lang='scss'>
@import '@/assets/scss/base/_mixins.scss';
@import '@/assets/scss/base/_variables.scss';

.map-legend-wrapper {
  position: absolute;
  top: 22px;
  right: 19px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 4px;
  max-width: 220px;
  margin: 0;

  > div {
    padding: 0;
  }

  @include media(mobile) {
    top: 0;
    right: 0;
    border-radius: 0px;
    width: 100%;

    &.is-in-map-search-page {
      display: none;
    }
  }

  &.is-out-map {
    max-width: 100%;

    @include media(tablet-desktop) {
      display: none;
    }

    @include media(mobile) {
      position: relative;
      margin: 0;
      padding: 10px;
    }
  }

  .map-legend-item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 5px;
    }

    img {
      height: 20px;
    }
    .marker-label {
      text-align: start;
      margin-left: 7px;
      font-size: 14px;
      color: $color-grey-main;
    }
  }
}
</style>
