<script>
import { each } from 'lodash'
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { mapGetters } from 'vuex'
import { markerIcons } from '@/components/map/constants/mapSettings';

export default {
  name: 'GoogleMapMarkerClusterer',

  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    gardens: {
      type: Array,
      required: true,
    },
    clickToListings: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentCluster: null,
  }),

  render() {},

  computed: {
    ...mapGetters('map', [
      'markers',
    ]),
  },

  watch: {
    markers(newMarkers, oldMarkers) {
      if (newMarkers !== oldMarkers && newMarkers.length === this.gardens.length) {
        this.setCluster()
      }
    },
  },
  methods: {
    async setCluster() {
      if (this.currentCluster) {
        await this.unsetCurrentCluster()
      }

      if (this.map && this.markers) {
        this.currentCluster = new MarkerClusterer({
          map: this.map,
          markers: this.markers,
          renderer: {
            render: (clusters, stats) => {
              return this.customeRenderer(clusters, stats)
            },
          },
        })
        if (this.clickToListings) {
          this.currentCluster.onClusterClick = false
        }
      }
    },
    customeRenderer(clusters) {
      const clusterPosition = clusters.position
      const newCluster = new this.google.maps.Marker({
        position: clusterPosition,
        icon: {
          url: markerIcons.markerClusterPng,
          scaledSize: new this.google.maps.Size(46.5, 46.5),
          labelOrigin: new this.google.maps.Point(26.5, 12.5),
        },
        label: {
          text: String(clusters.markers.length),
          color: 'white',
          fontSize: '16px',
          fontStyle: 'italic',
        },
      })
      if (this.clickToListings) {
        this.google.maps.event.addListener(newCluster, 'click', function () {
          const boundingBox = new this.google.maps.LatLngBounds();
          each(clusters.markers, function (gardenMarker) {
            const markerLatLng = new this.google.maps.LatLng(gardenMarker.position.lat(), gardenMarker.position.lng())
            boundingBox.extend(markerLatLng)
          }.bind(this))
          const ne = boundingBox.getNorthEast()
          const sw = boundingBox.getSouthWest()

          const tLatLng = {
            lat: ne.lat() + 0.0000001,
            lng: ne.lng() + 0.0000001,
          }
          const bLatLng = {
            lat: sw.lat() - 0.0000001,
            lng: sw.lng() - 0.0000001,
          }

          const geoBounds = `${tLatLng.lat.toFixed(16)},${tLatLng.lng.toFixed(16)},${bLatLng.lat.toFixed(16)},${bLatLng.lng.toFixed(16)}`
          const urlQuery = {
            'filter[opening_type]': 'opening',
            'filter[geo_bounding_box]': geoBounds,
          };
          urlQuery.search_bounds = geoBounds

          this.$router.push({ name: 'List', query: urlQuery })
        }.bind(this))
      }
      return newCluster
    },

    unsetCurrentCluster() {
      return new Promise((resolve) => {
        this.currentCluster.setMap(null)
        resolve();
      });
    },

  },
};
</script>
