<template>
  <div class="sort-button-wrapper">
    <b-dropdown
      class="sort-button"
      variant="outline-secondary"
      :ref="section.type"
      right
      no-caret
    >
      <template #button-content>
        {{ buttonLabel }}
        <font-awesome-icon class="right-icon" :icon="['far', 'chevron-down']"/>
      </template>
      <div class="sort-dropdown">
        <b-dropdown-item-button
          class="sort-option"
          v-for="(option, optionIndex) in sortOptions"
          :key="optionIndex"
          @click="selectOption(option)"
          :pressed="selectedSort.label === option.label">
          <font-awesome-icon
            class="radio-button-icon"
            :icon="selectedSort.label === option.label ? radioIcons.selected : radioIcons.unSelected"/>
          {{ option.label }}
        </b-dropdown-item-button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SortButton',

  data: () => ({
    section: {
      label: 'Sort By',
      type: 'sort',
    },
    radioIcons: {
      unSelected: ['fal', 'square'],
      selected: ['fas', 'check-square'],
    },
    optionValue: null,
  }),

  computed: {
    ...mapGetters('sort', [
      'selectedSort',
      'sortOptions',
    ]),
    buttonLabel() {
      return this.selectedSort.label || this.section.label;
    },
  },

  methods: {
    ...mapActions('sort', [
      'updateSort',
    ]),

    async selectOption(option) {
      await this.updateSort(option)
    },
  },
}
</script>
<style lang="scss">
  .sort-buttons-wrapper {
    .dropdown-menu {
      & * {
        font-family: "NGS 2017", "Inter", sans-serif !important;
      }

      .b-dropdown-text {
        font-size: 24px;
      }

      .filter-dropdown,
      .filter-range-options * {
        font-size: 18px !important;
      }
    }

    .btn {
      &.dropdown-toggle {
        width: 143px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media (max-width: 1200px) {
      .btn-group,
      .btn-group-vertical,
      .dropdown {
        position: initial;
      }

      .dropdown-menu {
        top: 40px !important;
      }
    }
  }
</style>

<style scoped lang="scss">
  .sort-button-wrapper {
    margin-left: 1rem;

    @media (max-width: 430px) {
      margin: 0;
      width: 100%;
    }

    button.btn {
      svg {
          font-size: 12px;
      }
    }
  }

  .sort-dropdown {
    min-width: 5rem;
  }
  .sort-range {
    min-width: 19rem;
    .filter-range-option-button {
      &:not(:last-child) {
        margin-right: 0.539rem;
      }
    }
  }
  .sort-list-options-wrapper {
    max-height: 9.388rem;
    overflow-y: auto;
  }
</style>
