<template>
  <div class="list" :class="{ 'show-map' : showMap, 'show-list-map-buttons': showListMapButtons }">
    <div v-if="loading" class="loading-overlay">
      <div>
        <font-awesome-icon
          class="fa-pulse"
          size="3x"
          :icon="['fad', 'spinner']"
          style="--fa-secondary-color: #EAE3C5;--fa-secondary-opacity:1;--fa-primary-color: #ffcc00"
        />
      </div>
    </div>
    <div
      v-if="showListMapButtons"
      class="col-12 d-flex d-lg-none justify-content-center show-hide-map-button-wrapper"
      :class="{ 'd-flex' : showListMapButtons }"
    >
      <button
        :disabled="loading"
        :class="['view-list-map-button', 'btn', showList && 'view-list-map-button--active']"
        @click="displayListView"
      >
        <font-awesome-icon :icon="['fal', 'list']"/>
        List
      </button>
      <button
        :disabled="loading"
        :class="['view-list-map-button', 'btn', showMap && 'view-list-map-button--active']"
        @click="displayMapView"
      >
        <font-awesome-icon :icon="['fal', 'map']"/>
        Map
      </button>
    </div>
    <div id="list-section" class="col-lg-6 col-12 px-0" :class="{ 'show-list' : showList }">
      <ListHeader/>
      <Listings/>
    </div>
    <div id="map-section" class="col-12 px-0"  :class="{ 'col-lg-6 show-list' : showList, 'home-page' : isHomePage }">
      <ListMapWrapper
        :topMap="!mapLegendAtBottom"
        :isOutMap="mapLegendAtBottom"
        class="map-wrapper"
        :key="listMapWrapperKey"
        :showMapLegend="showMapLegend"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { split } from 'lodash'
import ListHeader from '@/components/gardenList/ListHeader'
import Listings from '@/components/gardenList/Listings'
import ListMapWrapper from '@/components/map/ListMapWrapper'
import { getFullBoundsFromArray } from '@/services/location'
import eventBus from '@/services/eventBus'

export default {
  name: 'List',

  data: () => ({
    showListMapButtons: false,
    showMap: true,
    showList: false,
    reloadMap: true,
    mapLegendAtBottom: false,
    currentWidth: null,
    listMapWrapperKey: 1,
    showMapLegend: true,
    previousRoute: '',
    routeQuery: null,
    isHomePage: false,
  }),

  components: {
    ListHeader,
    Listings,
    ListMapWrapper,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from?.name;
    });
  },

  async created() {
    this.setWidthAndHeight();
    window.addEventListener('resize', this.setWidthAndHeight);
  },

  destroyed() {
    window.removeEventListener('resize', this.setWidthAndHeight);
  },

  updated() {
    this.setWidthAndHeight(false);
  },

  computed: {
    ...mapGetters('filter', [
      'sectionFilterSelected',
    ]),

    ...mapGetters('garden', [
      'gardens',
      'garden',
      'loadingGardenList',
      'mapSearch',
    ]),

    ...mapGetters('map', [
      'geoBoundingBox',
      'loadingMap',
    ]),

    ...mapGetters('location', [
      'useCurrentLocation',
    ]),

    filteredGeoBoundingBox() {
      if (this.sectionFilterSelected('geo_bounding_box').length) {
        return split(this.sectionFilterSelected('geo_bounding_box'), ',')
      }
      return false
    },

    viewButtonContent() {
      if (this.showList) {
        return {
          label: 'Map',
          icon: ['fal', 'map'],
        };
      }

      return {
        label: 'List',
        icon: ['fal', 'list'],
      };
    },
    loading() {
      return this.loadingGardenList || this.loadingMap
    },
  },

  watch: {
    '$route.path': {
      handler(path) {
        if (this.garden) {
          this.clearGarden()
        }

        if (path === '/') {
          this.showListMapButtons = false;
          this.showList = false;
          this.setHeights();
          this.fetchAllGardens();
          const headerHeight = document.querySelector('#header')
          this.resizeObserver = new ResizeObserver(this.setHeights).observe(headerHeight)

          this.routeQuery = null
          this.isHomePage = true
        } else {
          this.isHomePage = false
          this.showListMapButtons = true
          this.displayListView();

          this.updatePage();
        }
        this.previousRoute = path
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions('garden', [
      'filterGardens',
      'clearGarden',
      'fetchAllGardens',
      'updateMapSearch',
    ]),

    setGeoBoundingBoxFromFilter() {
      const bounds = getFullBoundsFromArray(this.filteredGeoBoundingBox)
      this.filterGardens({ addGeoBoundingBox: { bounds } })
    },
    updatePage() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        if (!this.geoBoundingBox && this.filteredGeoBoundingBox) {
          this.setGeoBoundingBoxFromFilter()
        } else if (this.routeQuery !== this.$route.query) {
          this.filterGardens();
        }

        this.routeQuery = this.$route.query
      });
    },
    displayListView() {
      if (window.innerWidth > 991) {
        this.showMap = true;
        this.showList = true;
        this.updateMapSearch(false)
      } else if (this.mapSearch) {
        this.updateMapSearch(false)
      } else {
        this.showMap = false;
        this.showList = true;
      }
    },
    displayMapView() {
      this.showMap = true;
      this.showList = false;
    },
    async setWidthAndHeight(updatePageState = false) {
      await this.setWidth();
      this.setHeights(updatePageState);
    },
    setWidth() {
      if (!this.loading) {
        this.$nextTick(function () {
          // When a phone/tablet gets rotated, we update the listMapWrapperKey.
          // This key is used in the ListMapWrapper component and changing it will
          // force it to reload.
          // This prevents issues with the map trying to load pins while the new
          // bounds are still being generated.
          if (this.currentWidth && (this.currentWidth !== window.outerWidth)) {
            this.listMapWrapperKey = Math.round(new Date().getTime() / 1000).toString();
          }

          this.currentWidth = window.outerWidth;

          if (this.currentWidth < 840) {
            this.mapLegendAtBottom = true;
          } else {
            this.mapLegendAtBottom = false;
          }
        });
      }
    },
    setHeights() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been re-rendered
        if (this.$route.path === '/') {
          const headerHeight = document.querySelector('#header').offsetHeight;
          const showHideMapButton = document.querySelector('.show-hide-map-button-wrapper');
          const showHideMapButtonHeight = showHideMapButton?.offsetHeight || 0;
          const headerHeightTotal = headerHeight
          const bottomMapLabels = document.querySelector('.is-out-map');
          const bottomMapLabelsHeight = bottomMapLabels?.offsetHeight || 0;

          const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
          const mapSection = (vh - headerHeightTotal);

          document.getElementById('map-section').style.height = `${mapSection - showHideMapButtonHeight}px`;
          const mapWrapper = document.querySelector('#map-wrapper')
          if (mapWrapper) {
            document.getElementById('map-wrapper').style.height = `${mapSection - bottomMapLabelsHeight}px`;
          }
        } else {
          const headerHeight = document.querySelector('#header').offsetHeight;
          const showHideMapButton = document.querySelector('.show-hide-map-button-wrapper').offsetHeight;
          const bottomMapLabels = document.querySelector('.is-out-map');
          let bottomMapLabelsSize = 0;

          if (bottomMapLabels && bottomMapLabels.offsetHeight) {
            bottomMapLabelsSize = 120;
          }

          const headerTopWrapper = document.querySelector('#top-wrapper')
          const headerTopWrapperStyle = getComputedStyle(headerTopWrapper)
          const headerTopWrapperStyleMarginTop = parseInt(headerTopWrapperStyle.marginTop, 10)

          const headerHeightTotal = headerHeight + headerTopWrapperStyleMarginTop

          const listHeader = document.querySelector('#list-header-wrapper')
          const listHeaderHeight = listHeader.offsetHeight
          const listHeaderStyle = getComputedStyle(listHeader)
          const listHeaderMarginTop = parseInt(listHeaderStyle.marginTop, 10)
          const listHeaderMarginBottom = parseInt(listHeaderStyle.marginBottom, 10)
          const listHeaderHeightTotal = listHeaderHeight + listHeaderMarginTop + listHeaderMarginBottom

          const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0)
          const listingsWrapper = vh - (headerHeightTotal + listHeaderHeightTotal) - showHideMapButton;
          const mapSection = (vh - headerHeightTotal) - bottomMapLabelsSize - showHideMapButton;

          document.getElementById('map-section').style.height = `${mapSection}px`;
          document.getElementById('listings-wrapper').style.height = `${listingsWrapper}px`;
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.view-list-map-button {
  flex: 1;
  border-radius: 0;
  border: none;

  &:focus,
  &:focus-within {
    border: none;
  }

  &--active {
    background-color: rgba(255,204,0,0.2);
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #8383837d;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-hide-map-button-wrapper {
  background-color: #fff;
  z-index: 2;
  padding: 0;
  border-bottom: 1px solid #8383837d;
  border-top: 1px solid #8383837d;

  > button {
    padding: 17px;

    &:first-of-type {
      border-right: 1px solid #8383837d;
    }
  }
}

.list {
  position: relative;
  display: flex;
  align-items: stretch;

  &.show-map {
    #list-section {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    #map-section {
      left: 0;
    }

    &.show-list-map-buttons {
      #map-section {
        @include media-breakpoint-down(md) {
          z-index: 1;
          position: absolute;
          top: 54px;
          left: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  #list-section {
    z-index: 2;
    display: none;
    background-color: white;

    &.show-list {
      display: flex;
      flex-flow: column;
    }

    @include media-breakpoint-down(md) {
      overflow-y: scroll;
    }
  }

  #map-section {
    @include media-breakpoint-down(md) {
      z-index: 3;
      position: absolute;
      top: 54px;
      left: 0;

      &.show-list {
        z-index: 1;
      }

      &.home-page {
        top: 0;
      }
    }

    .map-wrapper {
      position: relative;
      height: 100%;
    }
  }
}
</style>
