<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    :apiKey="apiKey"
    :google="google">
    <template slot-scope="{ google, map }">
      <MapLegend v-if="showMapLegend" :isInMapSearchPage="topMap" :isOutMap="isOutMap"/>
      <GoogleMapMarkers
        :gardens="gardens"
        :google="google"
        :map="map"
        :addInfoWindows="true"
      />
      <GoogleMapMarkerClusterer
        :gardens="gardens"
        :google="google"
        :map="map"/>
    </template>
  </GoogleMapLoader>
</template>

<script>
import { mapGetters } from 'vuex'

import GoogleMapsApiLoader from 'google-maps-api-loader'
import eventBus from '@/services/eventBus'

import GoogleMapLoader from '@/components/map/GoogleMapLoader'
import GoogleMapMarkers from '@/components/map/GoogleMapMarkers'
import GoogleMapMarkerClusterer from '@/components/map/GoogleMapMarkerClusterer'
import MapLegend from '@/components/map/MapLegend'

import { mapSettings } from '@/components/map/constants/mapSettings'

export default {
  name: 'ListMapWrapper',

  components: {
    GoogleMapLoader,
    GoogleMapMarkers,
    GoogleMapMarkerClusterer,
    MapLegend,
  },

  props: {
    topMap: {
      type: Boolean,
      default: false,
    },
    isOutMap: {
      type: Boolean,
      default: false,
    },
    showMapLegend: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    google: null,
    initialiseMap: false,
    mapCenter: {
      lat: 0,
      lng: 0,
    },
    updatingId: null,
  }),

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    })

    this.google = googleMapApi
  },

  computed: {
    ...mapGetters('garden', [
      'gardens',
    ]),

    ...mapGetters('location', [
      'selectedLocationLatLng',
      'selectedLocationBounds',
    ]),

    ...mapGetters('map', [
      'mapBounds',
      'geoBoundingBox',
      'searchBounds',
    ]),

    ...mapGetters('filter', [
      'sectionFilterSelected',
    ]),

    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      }
    },
    gardenSearch() {
      return this.sectionFilterSelected('garden')
    },
  },

  watch: {
    async geoBoundingBox(newGeoBoundingBox, oldGeoBoundingBox) {
      if (newGeoBoundingBox && newGeoBoundingBox !== oldGeoBoundingBox && this.google) {
        if (!this.initialiseMap) {
          await this.getMapCenter()

          this.initialiseMap = true
        }
      }
    },
    async google(value) {
      if (value && this.geoBoundingBox) {
        if (!this.initialiseMap) {
          await this.getMapCenter()

          this.initialiseMap = true
        }
      }
    },
    initialiseMap(value) {
      if (value && this.mapCenter) {
        eventBus.$emit('initialize-map')
      }
    },
    selectedLocationLatLng(newLocation, oldLocation) {
      if (newLocation && (newLocation?.time !== this.updatingId && (!oldLocation || (newLocation?.lat !== oldLocation?.lat || newLocation?.lng !== oldLocation?.lng)))) {
        this.updatingId = newLocation?.time

        this.setMapCenter()
      }
    },
    selectedLocationBounds(newBounds, oldBounds) {
      if (newBounds && (newBounds?.time !== this.updatingId && (!oldBounds || ((newBounds?.northeast?.lat !== oldBounds?.northeast?.lat || newBounds?.northeast?.lng !== oldBounds?.northeast?.lng) || (newBounds?.southwest?.lat !== oldBounds?.southwest?.lat || newBounds?.southwest?.lng !== oldBounds?.southwest?.lng))))) {
        this.updatingId = newBounds?.time

        eventBus.$emit('reinitialize-map')
      }
    },
  },

  methods: {
    getMapCenter() {
      return new Promise((resolve) => {
        const topRight = new this.google.maps.LatLng(this.geoBoundingBox.top_right.lat, this.geoBoundingBox.top_right.lng)
        const bottomLeft = new this.google.maps.LatLng(this.geoBoundingBox.bottom_left.lat, this.geoBoundingBox.bottom_left.lng)

        const centerBounds = new this.google.maps.LatLngBounds()
        centerBounds.extend(bottomLeft)
        centerBounds.extend(topRight)

        this.mapCenter = {
          lat: centerBounds.getCenter().lat(),
          lng: centerBounds.getCenter().lng(),
        }
        resolve()
      })
    },
    setMapCenter() {
      this.mapCenter = this.selectedLocationLatLng
      eventBus.$emit('reinitialize-map')
    },

  },
}
</script>
