<script>
import Vue from 'vue'
import {
  each,
  find,
  filter,
  includes,
} from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import MarkerInfoWindow from '@/components/map/MarkerInfoWindow'
import { markerIcons } from '@/components/map/constants/mapSettings';

export default {
  name: 'GoogleMapMarkers',

  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    gardens: {
      type: Array,
      required: true,
    },
    addInfoWindows: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    openInfoWindow: null,
    initialBoundsSet: false,
    activeMarker: null,
    selectedLocation: null,
  }),

  render() {},

  computed: {
    ...mapGetters('map', [
      'mapBounds',
      'markers',
    ]),

    ...mapGetters('location', [
      'selectedLocationLatLng',
    ]),

    ...mapGetters('ngsicons', [
      'findIconByTag',
      'localTags',
    ]),
  },

  watch: {
    mapBounds(value) {
      if (value && !this.initialBoundsSet) {
        this.setLocalMarkers()
      }
    },

    gardens(newGardens) {
      if (newGardens) {
        this.setLocalMarkers()
      }
    },

    markers(value) {
      if (value) {
        this.generateInfoWindows()
      }
    },
  },

  methods: {
    ...mapActions('map', [
      'setMarkers',
      'updateLoadingMap',
    ]),

    gardenTreeIcon(garden) {
      return garden.garden_type_id === 2 ? this.findIconByTag('flowers') : this.findIconByTag('plants');
    },

    setLocalMarkers() {
      const markers = []
      this.updateLoadingMap(true)
      if (this.selectedLocation) {
        this.selectedLocation.setMap(null)
        this.selectedLocation = null
      }
      if (this.selectedLocationLatLng) {
        const markerIcon = {
          url: markerIcons.markerLocation,
          scaledSize: new this.google.maps.Size(50, 50), // scaled size
        }

        this.selectedLocation = new this.google.maps.Marker({
          position: this.selectedLocationLatLng,
          map: this.map,
          icon: markerIcon,
        })
      }
      if (this.gardens.length) {
        each(this.gardens, function (garden) {
          const isGroup = garden.garden_type_id === 2

          const hasOpenings = filter(garden.openings, function (opening) {
            return includes([1, 2, 5, 6], opening.garden_opening_type_id)
          }).length > 0;

          const hasArrangement = filter(garden.openings, function (opening) {
            return includes([3, 4], opening.garden_opening_type_id)
          }).length > 0;

          const hasOpeningsAndArrangement = (hasOpenings && hasArrangement) ?? false;

          let markerType = hasOpeningsAndArrangement ? 'markerOpeningAndArrangement'
            : hasOpenings ? 'markerOpening'
              : hasArrangement ? 'markerArrangement'
                : null
          if (isGroup && !hasOpeningsAndArrangement) {
            markerType = `${markerType}Group`
          }

          const now = new Date()
          const openings = filter(garden.openings, function (opening) {
            return new Date(opening.end_date) >= now
          })

          if (!openings.length) {
            markerType = 'markerClosed'
          }
          const markerIcon = {
            url: markerIcons[markerType],
            scaledSize: new this.google.maps.Size(46.5, 46.5), // scaled size
          }
          const newMarker = new this.google.maps.Marker({
            position: garden.position,
            id: garden.id,
            icon: markerIcon,
          })
          markers.push(newMarker)
        }.bind(this))
      }
      if (!this.initialBoundsSet) {
        this.initialBoundsSet = true
      }
      this.setMarkers(markers)
    },

    getBoundsFromGarden(garden) {
      const topLatLng = {
        lat: garden.position.lat + 0.0000001,
        lng: garden.position.lng + 0.0000001,
      }
      const bottomLatLng = {
        lat: garden.position.lat - 0.0000001,
        lng: garden.position.lng - 0.0000001,
      }

      const geoBounds = `${topLatLng.lat.toFixed(16)},${topLatLng.lng.toFixed(16)},${bottomLatLng.lat.toFixed(16)},${bottomLatLng.lng.toFixed(16)}`

      return geoBounds
    },

    generateInfoWindows() {
      if (this.addInfoWindows) {
        const InfoWindow = Vue.extend(MarkerInfoWindow)

        each(this.markers, function (marker) {
          if (marker.id) {
            this.google.maps.event.addListener(marker, 'click', function () {
              const garden = find(this.gardens, ['id', marker.id])
              const treeIcon = this.gardenTreeIcon(garden)
              const loadedTags = this.localTags(garden.tags)
              const infoWindowInstance = new InfoWindow({
                propsData: {
                  garden,
                  treeIcon,
                  loadedTags,
                },
              })
              const currentPosition = marker.getPosition();
              const zoomLevelXaxisOffset = this.calculateZoomLevelOffset();
              const alteredPosition = {
                lat: currentPosition?.lat() + zoomLevelXaxisOffset,
                lng: currentPosition?.lng(),
              };

              infoWindowInstance.$mount();

              const infoWindow = new this.google.maps.InfoWindow({
                content: infoWindowInstance.$el,
                disableAutoPan: true,
              })

              this.activeMarker = marker;
              this.openWindow(infoWindow, marker)
              this.map.setCenter(alteredPosition);
            }.bind(this))
          }
        }.bind(this))

        this.google.maps.event.addListener(this.map, 'click', function () {
          if (this.openInfoWindow) {
            this.openInfoWindow.close()
            this.activeMarker = null
          }
        }.bind(this))
      }
      if (!this.addInfoWindows) {
        each(this.markers, function (marker) {
          if (marker.id) {
            const garden = find(this.gardens, ['id', marker.id])

            this.google.maps.event.addListener(marker, 'click', function () {
              const geoBounds = this.getBoundsFromGarden(garden);
              const urlQuery = {
                'filter[opening_type]': 'opening',
                'filter[geo_bounding_box]': geoBounds,
              };
              urlQuery.search_bounds = geoBounds;

              this.$router.push({ name: 'List', query: urlQuery })
            }.bind(this))
          }
        }.bind(this))
      }

      if (this.openInfoWindow && this.activeMarker) {
        setTimeout(() => {
          this.openInfoWindow.open(this.map, this.activeMarker)
        }, 100);
      }
    },
    async openWindow(infoWindow, marker) {
      if (this.openInfoWindow) {
        await this.openInfoWindow.close()
      }
      this.openInfoWindow = infoWindow
      this.openInfoWindow.shouldFocus = false
      this.openInfoWindow.open(this.map, marker)
    },
    calculateZoomLevelOffset() {
      const zoomLevel = this.map.getZoom();

      switch (zoomLevel) {
        case 6:
          return 1;
        case 7:
          return 0.7;
        case 8:
          return 0.4;
        case 9:
          return 0.3;
        case 10:
          return 0.15;
        case 11:
          return 0.05;
        case 12:
          return 0.025;
        case 13:
          return 0.01;
        case 14:
        case 15:
          return 0.005;
        case 16:
          return 0.001;
        default:
          return 0;
      }
    },

  },
};
</script>
<style lang='scss'>
.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0px;
  overflow: visible;
  button {
    &.gm-ui-hover-effect {
      display: none !important;
    }
  }
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-style-iw-t::after {
  display: none;
}

</style>
